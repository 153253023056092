// BottomTabNavigator.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchScreen from './SearchScreen';
import TrackingExpense from './TrackingExpense';
import RecordedExpense from './RecordedExpense';
import { useAuth } from '../Logins/AuthContext';
import './BottomTabNavigator.css'; // CSSファイルをインポート

const BottomTabNavigator: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('Search'); // Default to "Search"
  const [popupVisible, setPopupVisible] = useState<boolean>(false); 
  const { signOut } = useAuth();
  const navigate = useNavigate(); 
  const { t, i18n } = useTranslation(); 
  const [searchScreenKey, setSearchScreenKey] = useState<number>(0); // 新規追加

  const logout = () => {
    signOut()
      .then(() => {
        alert(t("logout_success")); 
        navigate('/login'); 
      })
      .catch((error) => {
        alert(t("logout_failed") + error.message);
      });
  };

  const Tab: React.FC<{ name: string; onPress?: () => void }> = ({ name, onPress }) => (
    <div
      className={activeTab === name ? 'tabActive' : 'tab'}
      onClick={onPress || (() => setActiveTab(name))}
    >
      <span className={activeTab === name ? 'tabTextActive' : 'tabText'}>
        {t(name)}
      </span>
    </div>
  );

  return (
    <div className="container">
      {/* Popup */}
      {popupVisible && (
        <div className="popupOverlay">
          <div className="popupContent">
            <h3>{t("logout")}</h3>
            <p>{t("confirm_logout")}</p>
            <div className="buttonGroup">
              <button
                className="buttonClose"
                onClick={() => {
                  setPopupVisible(false);
                  logout();
                }}
              >
                {t("yes")}
              </button>
              <button className="buttonCancel" onClick={() => setPopupVisible(false)}>
                {t("no")}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Header with Language Switch */}
      <header className="header">
        {/* 左セクション: 空白または将来の要素用 */}
        <div className="headerLeft"></div>

        {/* 中央セクション: タイトル */}
        <div className="headerCenter">
          <h1 
            className="headerText" 
            onClick={() => {
              setActiveTab('Search'); 
              setSearchScreenKey(prevKey => prevKey + 1); // キーをインクリメントしてリセット
            }} // On click, activate 'Search' and reset SearchScreen
          >
            {t("app_name")}
          </h1>
        </div>

        {/* 右セクション: 言語切替ボタン */}
        <div className="headerRight">
          <div className="languageSwitchContainer">
            <button className="languageButton" onClick={() => i18n.changeLanguage('ja')}>{t("日本語")}</button>
            <button className="languageButton" onClick={() => i18n.changeLanguage('en')}>{t("English")}</button>
          </div>
        </div>
      </header>

      {/* Tab bar */}
      <div className="tabBar">
        <Tab name="Search" />
        <Tab name="Tracking Expense" />
        <Tab name="Recorded Expense" />
        <Tab name="logout" onPress={() => setPopupVisible(true)} /> {/* 修正: "logout" を小文字に */}
      </div>

      {/* Content */}
      <div className="screenContainer">
        {activeTab === 'Search' && <SearchScreen key={searchScreenKey} />} {/* keyを渡す */}
        {activeTab === 'Tracking Expense' && <TrackingExpense />}
        {activeTab === 'Recorded Expense' && <RecordedExpense />}
      </div>
    </div>
  );
};

export default BottomTabNavigator;
