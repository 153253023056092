// SearchScreen.tsx
import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Button, Form, Card, Spinner } from 'react-bootstrap';
import {
  FaPhone,
  FaMapMarkerAlt,
  FaTag,
  FaCheckCircle,
  FaTimesCircle,
  FaYelp,
  FaGoogle,
  FaUtensils,
} from 'react-icons/fa';
import './styles.css'; // スタイルはCSSクラスで管理
import { useTranslation } from 'react-i18next';

interface Restaurant {
  id: string;
  name: string;
  image_url: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  location: {
    address1: string;
    city: string;
    state: string;
    zip_code: string;
  };
  display_phone: string;
  is_closed: boolean;
  distance: number;
  url: string;
  price: string;
  categories: { title: string }[];
  transactions: string[];
  hours?: { is_open_now: boolean }[];
  source: 'google';
  yelp_url?: string;
  hotpepper_url?: string;
}

const CustomPopup: React.FC<{
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
}> = ({ show, onClose, children }) => {
  if (!show) return null;

  return (
    <div className="custom-popup-overlay" role="dialog" aria-modal="true">
      <div className="custom-popup">
        <button
          className="custom-popup-close-button"
          onClick={onClose}
          aria-label="Close"
        >
          ✖️
        </button>
        {children}
      </div>
    </div>
  );
};

// 距離を計算する関数（ハバースの公式を使用）
function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const R = 6371e3; // 地球の半径（メートル）
  const φ1 = (lat1 * Math.PI) / 180;
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) *
      Math.cos(φ2) *
      Math.sin(Δλ / 2) *
      Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // メートル単位
  return distance;
}

// 住所を緯度・経度に変換する関数
async function geocodeAddress(
  address: string
): Promise<{ latitude: number; longitude: number }> {
  const response = await fetch(
    `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
      address
    )}`
  );
  const data = await response.json();

  if (data && data.length > 0) {
    return {
      latitude: parseFloat(data[0].lat),
      longitude: parseFloat(data[0].lon),
    };
  } else {
    throw new Error('住所から緯度・経度を取得できませんでした');
  }
}

// 画像URLを取得する関数
const getPhotoUrl = (photoReference: string) => {
  return `/api/place/photo?photo_reference=${photoReference}`;
};

const SearchScreen: React.FC = () => {
  const { t } = useTranslation();
  const [location, setLocation] = useState<string>('');
  const [term, setTerm] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState<Restaurant | null>(null);
  const [filterOption, setFilterOption] = useState<string>('distance');
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);
  const [userCoordinates, setUserCoordinates] = useState<{ latitude: number; longitude: number } | null>(null);
  const [pages, setPages] = useState<Restaurant[][]>([]); // 各ページの結果を格納
  const [currentPage, setCurrentPage] = useState<number>(0); // 現在のページ番号
  const [isLoading, setIsLoading] = useState<boolean>(false); // ローディングステート
  const [hasSearched, setHasSearched] = useState<boolean>(false); // 検索が行われたかを管理

  // リファレンスを作成してスクロール位置を制御
  const resultsRef = useRef<HTMLDivElement>(null);

  // スクロールを制御するuseEffect
  useEffect(() => {
    if (hasSearched && resultsRef.current) {
      resultsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentPage, hasSearched]);

  const sortResults = (results: Restaurant[], sortBy: string) => {
    return [...results].sort((a, b) => {
      if (sortBy === 'price_low_to_high') {
        return a.price.length - b.price.length;
      } else if (sortBy === 'price_high_to_low') {
        return b.price.length - a.price.length;
      } else if (sortBy === 'distance') {
        return a.distance - b.distance;
      }
      return 0;
    });
  };

  // 現在のページの結果を取得し、ソートする
  const currentPageResults = useMemo(() => {
    if (pages[currentPage]) {
      return sortResults(pages[currentPage], filterOption);
    }
    return [];
  }, [pages, currentPage, filterOption]);

  const fetchData = async (
    pagetoken: string | null = null,
    isNewSearch: boolean = false
  ): Promise<void> => {
    if (!location.trim() || !term.trim()) {
      setError(t('error_input'));
      return;
    }
    setError('');
    setIsLoading(true); // ローディング開始

    let latitude = '';
    let longitude = '';

    try {
      if (isNewSearch || !userCoordinates) {
        const coords = await geocodeAddress(location);
        latitude = coords.latitude.toString();
        longitude = coords.longitude.toString();
        setUserCoordinates(coords);
      } else {
        latitude = userCoordinates.latitude.toString();
        longitude = userCoordinates.longitude.toString();
      }
    } catch (error) {
      setError(t('location_fetch_error'));
      setIsLoading(false);
      return;
    }

    const apiUrl = `/api/search/google?latitude=${latitude}&longitude=${longitude}&term=${encodeURIComponent(
      term
    )}${pagetoken ? `&pagetoken=${pagetoken}` : ''}`;

    try {
      console.log('API URL:', apiUrl); // デバッグ用
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.error) {
        setError(`エラーが発生しました: ${data.error}`);
        setIsLoading(false);
        return;
      }

      setNextPageToken(data.next_page_token || null);

      const formattedResults = data.results.map((place: any) => {
        const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(place.name)}&query_place_id=${place.place_id}`;
        
        // Yelp URLの構築（簡易検索）
        const yelpUrl = `https://www.yelp.com/search?find_desc=${encodeURIComponent(place.name)}&find_loc=${encodeURIComponent(location)}`;
        
        // Hotpepper URLの構築（日本語ロケールを想定）
        const hotpepperUrl = `https://www.hotpepper.jp/search/?keyword=${encodeURIComponent(place.name)}&lat=${place.geometry.location.lat}&lng=${place.geometry.location.lng}`;

        return {
          id: place.place_id,
          name: place.name,
          image_url: place.photos
            ? getPhotoUrl(place.photos[0].photo_reference)
            : '/placeholder-image.png', // プレースホルダー画像
          coordinates: {
            latitude: place.geometry.location.lat,
            longitude: place.geometry.location.lng,
          },
          location: {
            address1: place.vicinity,
            city: '',
            state: '',
            zip_code: '',
          },
          display_phone: '', // Place Details APIを使用すれば取得可能
          is_closed: place.business_status !== 'OPERATIONAL',
          distance: userCoordinates
            ? calculateDistance(
                userCoordinates.latitude,
                userCoordinates.longitude,
                place.geometry.location.lat,
                place.geometry.location.lng
              )
            : 0, // 距離を計算
          url: googleMapsUrl, // Google Maps URL
          price: place.price_level
            ? '$'.repeat(place.price_level)
            : '',
          categories: place.types.map((type: string) => ({
            title: type,
          })),
          transactions: [],
          source: 'google',
          yelp_url: yelpUrl,
          hotpepper_url: hotpepperUrl,
        };
      });

      if (isNewSearch) {
        // 新しい検索の場合、結果を置き換える
        setPages([formattedResults]);
        setCurrentPage(0);
        setHasSearched(true); // 検索が行われたことを記録
      } else {
        if (formattedResults.length > 0) {
          setPages((prevPages) => [...prevPages, formattedResults]);
          setCurrentPage((prevPage) => prevPage + 1);
        }
      }

      // スクロールはuseEffectで制御
    } catch (error) {
      console.error('データ取得エラー:', error);
      setError(t('google_api_fetch_error'));
    } finally {
      setIsLoading(false); // ローディング終了
    }
  };

  const handleSearch = async () => {
    setPages([]);
    setUserCoordinates(null);
    setCurrentPage(0); // ページ番号をリセット
    setHasSearched(false); // 検索が行われるまでページネーションを非表示にする
    await fetchData(null, true); // isNewSearchをtrueに設定
  };

  const handleNextPage = async () => {
    if (currentPage + 1 < pages.length) {
      // 既にフェッチ済みのページがある場合
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (nextPageToken) {
      // 新しいページをフェッチする場合
      await fetchData(nextPageToken);
    }
    // スクロールはuseEffectで制御
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
      // スクロールはuseEffectで制御
    }
  };

  const renderItem = (item: Restaurant) => (
    <Card
      key={item.id}
      className="card-container"
      onClick={() => {
        setSelectedRestaurant(item);
        setIsPopupVisible(true);
      }}
    >
      <Card.Img
        variant="top"
        src={item.image_url}
        alt={item.name}
        className="card-image"
        loading="lazy" // パフォーマンス向上のため
      />
      <Card.Body>
        <Card.Title className="card-title">{item.name}</Card.Title>
        <Card.Text className="card-text">
          <p>{item.price}</p>
          <p>{item.location.address1}</p>
          <p>{(item.distance / 1000).toFixed(2)} km</p>
        </Card.Text>
      </Card.Body>
    </Card>
  );

  return (
    <div className="app-container">
      {/* ヘッダー（検索フォーム） */}
      <div className="search-form-container">
        <div className="input-container">
          <Form.Control
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            placeholder={t('location_placeholder')}
            className="search-input"
          />
          <Form.Control
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            placeholder={t('term_placeholder')}
            className="search-input"
          />
        </div>
        <div className="button-container">
          <Button onClick={handleSearch} className="search-button">
            {t('search_button')}
          </Button>
        </div>

        <div className="filter-container">
          <label htmlFor="filter">{t('filter_by')}:</label>
          <select
            id="filter"
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
            className="filter-select"
          >
            <option value="distance">{t('filter_distance')}</option>
            <option value="price_low_to_high">
              {t('filter_price_low_to_high')}
            </option>
            <option value="price_high_to_low">
              {t('filter_price_high_to_low')}
            </option>
          </select>
        </div>
      </div>

      {/* メインコンテンツ */}
      <div className="main-container">
        {/* エラーメッセージ */}
        {error && (
          <div className="error-text">{error}</div>
        )}

        {/* ローディングインジケーター */}
        {isLoading && (
          <div className="loading-indicator">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}

        {/* スクロールターゲット */}
        <div ref={resultsRef}></div>

        {/* 検索結果 */}
        <div className="results-grid">
          {currentPageResults.map((item: Restaurant) => renderItem(item))}
        </div>

        {/* ページネーションボタンの条件付きレンダリング */}
        {hasSearched && (
          <div className="pagination-container">
            <Button
              onClick={handlePrevPage}
              className="pagination-button"
              disabled={currentPage === 0}
              aria-label={t('prev')}
            >
              {t('prev')}
            </Button>
            <Button
              onClick={handleNextPage}
              className="pagination-button"
              disabled={!nextPageToken && currentPage + 1 >= pages.length}
              aria-label={t('next')}
            >
              {t('next')}
            </Button>
          </div>
        )}
      </div>

      {/* 詳細ポップアップ */}
      <CustomPopup
        show={isPopupVisible}
        onClose={() => setIsPopupVisible(false)}
      >
        {selectedRestaurant && (
          <div className="popup-content">
            <div className="popup-image-container">
              <img
                src={selectedRestaurant.image_url}
                alt={selectedRestaurant.name}
                className="popup-image"
                loading="lazy" // パフォーマンス向上のため
              />
            </div>
            <div className="popup-details">
              <h3>{selectedRestaurant.name}</h3>
              <p>
                <FaPhone />{' '}
                {selectedRestaurant.display_phone || t('info_not_available')}
              </p>
              <p>
                <FaTag /> {selectedRestaurant.price}
              </p>
              <p>
                {(selectedRestaurant.distance / 1000).toFixed(2)} km
              </p>
              <p>
                <FaMapMarkerAlt />{' '}
                {selectedRestaurant.location.address1}
              </p>
              <p>
                {t('open_status')}:{' '}
                {selectedRestaurant.is_closed ? (
                  <FaTimesCircle color="red" />
                ) : (
                  <FaCheckCircle color="green" />
                )}
              </p>
              <p>
                {t('categories')}:{' '}
                {selectedRestaurant.categories
                  .map((cat) => cat.title)
                  .join(', ')}
              </p>
              {/* 外部リンク */}
              <div className="external-links">
                {selectedRestaurant.yelp_url && (
                  <a
                    href={selectedRestaurant.yelp_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-button"
                  >
                    <FaYelp style={{ marginRight: '5px' }} />
                    Yelp
                  </a>
                )}
                {selectedRestaurant.url && (
                  <a
                    href={selectedRestaurant.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-button"
                  >
                    <FaGoogle style={{ marginRight: '5px' }} />
                    Google Maps
                  </a>
                )}
                {selectedRestaurant.hotpepper_url && (
                  <a
                    href={selectedRestaurant.hotpepper_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-button"
                  >
                    <FaUtensils style={{ marginRight: '5px' }} />
                    Hotpepper
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </CustomPopup>
    </div>
  );
};

export default SearchScreen;
